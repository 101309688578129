import {GetServerSideProps} from 'next'
import getUniquePropertyPageStaticProps from 'src/utils/pages/getUniquePropertyPageStaticProps'
import getSeoPageBuilderPageProps from 'src/utils/pages/getSeoPageBuilderPageProps'
import {PROPERTY_PAGE_CHALETS_A_LOUER} from 'src/constants/route'
import {publicGetUrlRedirect} from 'src/services/api/redirect'
import {REDIRECT_DEACTIVATED} from 'src/constants/redirectReason'
import dynamic from 'next/dynamic'

//dynamic import for better performance, so we dont load both JS files at the same time
const SeoPageBuilderPage = dynamic(
  () => import('src/components/modules/page/SeoPageBuilderPage'),
  {ssr: true},
)

//dynamic import for better performance, so we dont load both JS files at the same time
const UniquePropertyPage = dynamic(
  () => import('src/components/modules/property/singlePage/UniquePropertyPage'),
  {ssr: true},
)

export default function PageSwitch(props: {type: string}) {
  let componentProps: any = {
    ...props,
  }

  delete componentProps.type

  if (props.type === 'page') {
    return <SeoPageBuilderPage {...componentProps} />
  }

  return <UniquePropertyPage {...componentProps} />
}

export const getServerSideProps: GetServerSideProps = async (context: any) => {
  const propertyProps = await getUniquePropertyPageStaticProps(context)

  const slugs: string[] = context.params ? context.params.slug : []
  const property = propertyProps.props.property
  if (property && property.is_active) {
    //default to property
    return propertyProps
  }

  // if no property, try page
  // first try page builder
  const pageProps = await getSeoPageBuilderPageProps(
    context,
    PROPERTY_PAGE_CHALETS_A_LOUER,
  )

  // if found page
  if (!pageProps.notFound) {
    return pageProps
  }

  // check name changes
  const redirect: {success: boolean; url: string; reason: string} =
    await publicGetUrlRedirect(slugs.join('/'))
  if (redirect && redirect.success) {
    //will be permanent if its a name changed
    //will not be permanent
    const permanent = redirect.reason !== REDIRECT_DEACTIVATED

    return {
      redirect: {
        permanent: permanent,
        destination: `/${PROPERTY_PAGE_CHALETS_A_LOUER}${redirect.url}`,
      },
      props: {},
    }
  }

  return {
    notFound: true,
    props: {},
  }
}
